import React, { useEffect, useRef } from 'react';
import './Partner.css';
import GridBackground from '../../../components/grid-background/GridBackground';

function Partner() {
  return (
    <div className="page-container">
      <h1 className='h-partner'><br /><br />Our Partners</h1>
      <div className="container">
        <GridBackground />
        <div className="grid-container">
          <div className="grid-item info-section">
            <div className="tagline">Energy Powder, Shaker</div>
            <h1>Klazmo</h1>
            <p>
            Klazmo's Energy has everything a real gamer needs. Sugar-free, easy to prepare, great taste (many nice flavors) can be mixed with water or milk.
            <br />
            <br />
            Our recommendation: The starter pack to try out with the free shaker.
            </p>
            <div className="buttons">
              <button onClick={() => window.open("https://www.klazmo.de/detail/index/sArticle/56/sCategory/43")}>Starter Pack</button>
              <button className='open-partner' onClick={() => window.open("https://www.klazmo.de/")}>Go Shopping</button>
            </div>
          </div>

          <div className="grid-item partner-section">
            <img src="/images/klazmo_sakura.png" alt="Klazmo Banner" className="jersey-image" />
          </div>
        </div>
      </div>
    </div>
  );
};
  
export default Partner;