// src/App.js
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from "react-router-dom";
import React from 'react';

// Components
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

// Pages
import Coaching from './pages/public_pages/coaching/Coaching';
import Merch from './pages/public_pages/merch/Merch';
import NotFound from './pages/public_pages/not-found/NotFound';
import Partner from './pages/public_pages/partner/Partner';
import Start from './pages/public_pages/start/Start';
import Teams from './pages/public_pages/teams/Teams';
import Contact from './pages/public_pages/contact/Contact';
import Imprint from './pages/public_pages/imprint/imprint';
import Socials from './pages/public_pages/socials/Socials';
import Tournaments from './pages/public_pages/tournaments/Tournaments';
import ContractFormIdBased from './pages/private_pages/contract-form-idbased/ContractFormIdBased';
import ContractAdminForm from './pages/private_pages/contract-admin-form/ContractAdminForm';
import AdminPanel from './pages/private_pages/admin-main-menu/AdminMainMenu'

// Styles and assets
import './App.css';
import './i18n';

const RedirectToDefaultLang = () => {
  let location = useLocation();
  const pathParts = location.pathname.split('/');
  if (pathParts.length < 2 || pathParts[1] === "") {
    return <Navigate to="/en/start" replace />;
  }
  return null;
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<RedirectToDefaultLang />} />
        <Route path="/:lang/*" element={<Layout />}>
          <Route path="start" element={<Start />} />
          <Route path="coaching" element={<Coaching />} />
          <Route path="teams" element={<Teams />} />
          <Route path="tournaments" element={<Tournaments />} />
          <Route path="partner" element={<Partner />} />
          <Route path="merch" element={<Merch />} />
          <Route path="socials" element={<Socials />} />
          <Route path="contact" element={<Contact />} />
          <Route path="imprint" element={<Imprint />} />
          <Route path="contract" element={<ContractFormIdBased />} />
          <Route path="prg-admin" element={<AdminPanel />} />
          <Route path="prg-admin/create-contract" element={<ContractAdminForm />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </Router>
  );
};

const Layout = () => {
  return (
    <div className="default-div">
      <Navbar />
      <Routes>
        <Route path="start" element={<Start />} />
        <Route path="coaching" element={<Coaching />} />
        <Route path="teams" element={<Teams />} />
        <Route path="tournaments" element={<Tournaments />} />
        <Route path="partner" element={<Partner />} />
        <Route path="merch" element={<Merch />} />
        <Route path="socials" element={<Socials />} />
        <Route path="contact" element={<Contact />} />
        <Route path="imprint" element={<Imprint />} />
        <Route path="contract" element={<ContractFormIdBased />} />
        <Route path="prg-admin" element={<AdminPanel />} />
        <Route path="prg-admin/create-contract" element={<ContractAdminForm />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <footer>
        <Footer />
      </footer>
    </div>
  );
};

export default App;
