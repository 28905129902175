import './Merch.css';
import GridBackground from '../../../components/grid-background/GridBackground';

function Merch() {
    return (
    <div className="page-container">
    <h1 className='h-merch'><br /><br />Merchandise</h1>
    <div className="container">
      <div className="grid-container-merch">
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/purge-hoodie/")}>
          <img src="/images/HoodieFrontMockup-Paragon-Black.webp" alt="Purge Hoodie" className="jersey-image" />
          <p>Purge Hoodie</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/purge-jersey/")}>
          <img src="/images/JerseyPrintfileFront-Purge.png" alt="Purge Jersey" className="jersey-image" />
          <p>Purge Jersey</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/purge-jogger/")}>
          <img src="/images/JoggerFrontMockup-Paragon-Black.webp" alt="Purge Jogger" className="jersey-image" />
          <p>Purge Jogger</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/purge-snapback/")}>
          <img src="/images/Snapback-Paragon-Black.webp" alt="Purge Snapback" className="jersey-image" />
          <p>Purge Snapback</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/purge-tshirt/")}>
          <img src="/images/tShirtMockupFront-Paragon-Black.webp" alt="Purge Tshirt" className="jersey-image" />
          <p>Purge Tshirt</p>
        </div>
        <div className="grid-item-merch info-section-merch" onClick={() => window.open("https://www.korazo.net/de/produkt/pruge-hoodie/")}>
          <img src="/images/ZipperMockup-Paragon.webp" alt="Purge Zip Hoodie" className="jersey-image" />
          <p>Purge Zip Hoodie</p>
        </div>
      </div>
    </div>
    </div>
  )};
  
export default Merch;