export const getCookie = (name) => {
    const cookies = document.cookie.split('; ');
    for (const cookie of cookies) {
        const [key, value] = cookie.split('=');
        if (key === name) return value;
    }
    return null;
};

export const validateSession = async () => {
    const sessionId = getCookie('connect.sid');
    if (!sessionId) return false;

    try {
        const response = await fetch(`https://api.prgpurge.com/prg/api/validate-session?cookie=${sessionId}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ sessionId }),
        });

        const result = await response.json();

        if (response.ok && result.valid) {
            return true;
        } else {
            deleteCookie('connect.sid');
            deleteCookie('connect.user');
            window.location.href = '/en/prg-admin'; // Redirect to AdminMainMenu
            //window.location.reload()
            return false;
        }
    } catch (error) {
        console.error('Error validating session:', error);
        return false;
    }
};

export const deleteCookie = (name, path = '/') => {
    document.cookie = `${name}=; path=${path}; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export const setCookie = (name, value, maxAge, path = '/', sameSite = 'Strict') => {
    const expiration = new Date(Date.now() + maxAge).toUTCString();
    document.cookie = `${name}=${value}; path=${path}; expires=${expiration}; SameSite=${sameSite}`;
};

export const handleLogin = async (username, authCode) => {
    if (!username || !authCode) {
        return { success: false, message: 'Username and Authenticator Code are required!' };
    }

    try {
        const response = await fetch('https://api.prgpurge.com/prg/api/login', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, authCode }),
        });

        const result = await response.json();

        if (response.ok) {
            if (result.cookie) {
                const maxAge = 60 * 60 * 1000 * 2; // 2 hours
                setCookie('connect.sid', result.cookie, maxAge);
                setCookie('connect.user', result.user, maxAge);
            }
            return { success: true, message: 'Login successful! You are now logged in.' };
        } else {
            return { success: false, message: result.message || 'Login failed.' };
        }
    } catch (error) {
        console.error('Error:', error);
        return { success: false, message: 'An error occurred during login.' };
    }
};