import React, { useState } from 'react';
import TeamSection from '../../../components/team-container/TeamContainer.js';
import './Teams.css';

function Teams() {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedGame, setSelectedGame] = useState('All'); // State to track the selected game

  const valorantLOGO = "/images/valorantlogo.png"
  const fortniteLOGO = "/images/fortnitelogo.png"

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 1000);
  };

  const teams = [
    {
      isTeam: true,
      gameSrc: valorantLOGO,
      extraInfo: null,
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "The Main Team (Immo)",
      teamdesc: "Our Valorant Main Team, which represents Purge eSports, it contains the best players from our organization.",
      leaderNames: ["PRG Lowe"],
      coachNames: ["PRG Lowe"],
      playerNames: ["PRG aYm", "PRG kxV", "PRG ulTii"],
      ersatzNames: ["PRG Xenomorph"],
      tryoutNames: ["PRG 0dd", "PRG ostapkoTapko", "PRG emb1L", "PRG Juhl", "PRG Lecadi", "PRG soon yong", "PRG tratrock"],
      onCopy: () => handleCopy("g2letshe")
    },
    {
      isTeam: true,
      gameSrc: valorantLOGO,
      extraInfo: null,
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "The Navy Team (Asc-Immo)",
      teamdesc: "Our Navy Team, which represents the Ranks Asc to Immo.",
      leaderNames: ["PRG Amura"],
      coachNames: [""],
      playerNames: ["PRG ringerarn0s", "PRG Snam", "PRG Foxx ayy", "PRG Tobi", "PRG Wowolf"],
      ersatzNames: ["PRG unknown"],
      tryoutNames: ["PRG raven"],
      onCopy: () => handleCopy("amuraval")
    },
    {
      isTeam: true,
      gameSrc: valorantLOGO,
      extraInfo: null,
      logoSrc: "/logo192.png",
      teamgame: "Valorant",
      teamName: "The Elite Team (Immo)",
      teamdesc: "Our Elite Team, which represents the Rank Immo.",
      leaderNames: ["PRG Khang", "PRG Bexi"],
      coachNames: ["PRG 999snowy", "PRG Nyron."],
      playerNames: ["PRG Tekki"],
      ersatzNames: ["PRG Dart", "PRG shir♡"],
      tryoutNames: ["", ""],
      onCopy: () => handleCopy("khang3005")
    },
    //{
    //  logoSrc: "/logo192.png",
    //  teamgame: "R6",
    //  teamName: "Das Main Team",
    //  teamdesc: "Unser Rainbow Six Siege Main Team, welches Purge eSports Repräsentiert, dies enthält die besten Spieler aus unserer Organisation.",
    //  leaderNames: [""],
    //  coachNames: [""],
    //  playerNames: ["", "", "", ""],
    //  ersatzNames: [""],
    //  tryoutNames: [""],
    //  onCopy: () => handleCopy("")
    //},
    {
      isTeam: false,
      gameSrc: fortniteLOGO,
      extraInfo: {
        academyPlayer: false,
        proPlayer: true,
        socials: {
          twitter: "https://x.com/Kenfnr3",
          tracker: "https://fortnitetracker.com/profile/all/PRG%20Batman%20Ken/events?id=75cb3d7c-5d44-4af0-b85f-634a4c6aeeb5"
        }
      },
      logoSrc: "/images/kaiser.png",
      teamgame: "Fortnite",
      teamName: "PRG Ken",
      teamdesc: "Ken is one of our newest contracted Members, we see a huge potential in this Player.",
      leaderNames: [null],
      coachNames: [null],
      playerNames: [null],
      ersatzNames: [null],
      tryoutNames: [null],
      onCopy: () => handleCopy("")
    },
    {
      isTeam: false,
      gameSrc: fortniteLOGO,
      extraInfo: {
        academyPlayer: false,
        proPlayer: true,
        socials: {
          twitter: "https://x.com/Tahyfnbr",
          tracker: "https://fortnitetracker.com/profile/all/tahy%20fv/events?id=4b3c6658-ffd3-40e6-a762-682560e2c4c6"
        }
      },
      logoSrc: "/logo192.png",
      teamgame: "Fortnite",
      teamName: "PRG Tahy",
      teamdesc: "Tahy is one of our newest contracted Members, we see a huge potential in this Player.",
      leaderNames: [null],
      coachNames: [null],
      playerNames: [null],
      ersatzNames: [null],
      tryoutNames: [null],
      onCopy: () => handleCopy("")
    },
    {
      isTeam: false,
      gameSrc: fortniteLOGO,
      extraInfo: {
        academyPlayer: false,
        proPlayer: true,
        socials: {
          twitter: "https://x.com/qudrey2",
          tracker: "https://fortnitetracker.com/profile/all/prg%20qudrey"
        }
      },
      logoSrc: "/images/Qudrey.jpg",
      teamgame: "Fortnite",
      teamName: "PRG Qudrey",
      teamdesc: "Qudrey is one of our newest contracted Members, we see a huge potential in this Player.",
      leaderNames: [null],
      coachNames: [null],
      playerNames: [null],
      ersatzNames: [null],
      tryoutNames: [null],
      onCopy: () => handleCopy("")
    },
    {
      isTeam: false,
      gameSrc: fortniteLOGO,
      extraInfo: {
        academyPlayer: false,
        proPlayer: true,
        socials: {
          twitter: "https://x.com/ApolloQC",
          twitch: "https://www.twitch.tv/apolloqc_",
          tracker: "https://fortnitetracker.com/profile/all/prg%20Apollo"
        }
      },
      logoSrc: "/images/Apollo.png",
      teamgame: "Fortnite",
      teamName: "PRG Apollo",
      teamdesc: "Apollo is one of our newest contracted Members, we see a huge potential in this Player.",
      leaderNames: [null],
      coachNames: [null],
      playerNames: [null],
      ersatzNames: [null],
      tryoutNames: [null],
      onCopy: () => handleCopy("")
    },

    {
      isTeam: false,
      gameSrc: fortniteLOGO,
      extraInfo: {
        academyPlayer: false,
        proPlayer: true,
      },
      logoSrc: "/logo192.png",
      teamgame: "Fortnite",
      teamName: "PRG Jan Backes",
      teamdesc: "Jan Backes is a engaged Fortnite Pro Player of PRG.",
      leaderNames: [null],
      coachNames: [null],
      playerNames: [null],
      ersatzNames: [null],
      tryoutNames: [null],
      onCopy: () => handleCopy("")
    },
    {
      isTeam: false,
      gameSrc: fortniteLOGO,
      extraInfo: {
        academyPlayer: false,
        proPlayer: true,
        socials: {
          twitch: "https://www.twitch.tv/neykoufn/",
        }
      },
      logoSrc: "/images/prg_neykou.png",
      teamgame: "Fortnite",
      teamName: "PRG Neykou",
      teamdesc: "Neykou is a engaged Fortnite Pro Player of PRG.",
      leaderNames: [null],
      coachNames: [null],
      playerNames: [null],
      ersatzNames: [null],
      tryoutNames: [null],
      onCopy: () => handleCopy("")
    },
    {
      isTeam: false,
      gameSrc: fortniteLOGO,
      extraInfo: {
        academyPlayer: true,
        proPlayer: false,
      },
      logoSrc: "/logo192.png",
      teamgame: "Fortnite",
      teamName: "PRG Glc",
      teamdesc: "Glc is a engaged Fortnite Player of PRG.",
      leaderNames: [null],
      coachNames: [null],
      playerNames: [null],
      ersatzNames: [null],
      tryoutNames: [null],
      onCopy: () => handleCopy("")
    },
    {
      isTeam: false,
      gameSrc: fortniteLOGO,
      extraInfo: {
        academyPlayer: true,
        proPlayer: false,
      },
      logoSrc: "/logo192.png",
      teamgame: "Fortnite",
      teamName: "PRG Wroti",
      teamdesc: "Wroti is a engaged Fortnite Player of PRG.",
      leaderNames: [null],
      coachNames: [null],
      playerNames: [null],
      ersatzNames: [null],
      tryoutNames: [null],
      onCopy: () => handleCopy("")
    },
//    {
//      logoSrc: "/logo192.png",
//      teamgame: "R6",
//      teamName: "Das Main Team",
//      teamdesc: "ELITE ipsum do diam voluptua. Atores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
//      leaderNames: ["Name1"],
//      coachNames: ["Name2"],
//      playerNames: ["Name1"],
//      ersatzNames: ["Name1"],
//      tryoutNames: ["Name3", "Name5"],
//      onCopy: () => handleCopy("killstealgmbh")
//    },
  ];

  const filteredTeams = selectedGame === 'All' ? teams : teams.filter(team => team.teamgame === selectedGame);

  return (
    <div className="page-container">
      <h1><br />Our Teams</h1>
      <div className="button-container">
        <button class="gameBtn" onClick={() => setSelectedGame('All')}>All</button>
        <button class="gameBtn" onClick={() => setSelectedGame('Valorant')}>Valorant</button>
        <button class="gameBtn" onClick={() => setSelectedGame('Fortnite')}>Fortnite</button>
      </div>
      <div className="container">
        <div className="grid-container-teams">
          {filteredTeams.map((team, index) => (
            <React.Fragment key={index}>
              {/* <h1><br />{team.teamName}</h1> */}
              <TeamSection
                gameSrc={team.gameSrc}
                extraInfo={team.extraInfo}
                team={team.isTeam}
                logoSrc={team.logoSrc}
                teamgame={team.teamgame}
                teamName={team.teamName}
                teamdesc={team.teamdesc}
                leaderNames={team.leaderNames}
                coachNames={team.coachNames}
                playerNames={team.playerNames}
                ersatzNames={team.ersatzNames}
                tryoutNames={team.tryoutNames}
                onCopy={team.onCopy}
                showPopup={showPopup}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Teams;