import React from 'react';

const DiscordButton = () => {
    const handleClick = () => {
        window.open('https://discord.gg/purgeesports', '_blank');
    };

    return (
        <button className='dc-button' onClick={handleClick}>Discord</button>
    );
};

export default DiscordButton;
