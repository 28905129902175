import React, { useState } from 'react';
import TeamSection from '../../../components/coaching-container/CoachingContainer.js';
import './Coaching.css';

function Coaching() {
    return (
      <div className="page-container">
      <h1><br />Our Coaches</h1>
      <div className="container">
        <div className="grid-container-teams">
          <h2><br />We currently have no one who offers coaching!</h2>
          <p class="coaches-text">Would you like to offer coaching? Get in touch with us on Discord</p>
          {/* 
          <TeamSection 
             logoSrc="/logo192.png"
             coachName="Coach1"
             coachDesc="Lorem ipsum do invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet."
             coachGames={["Valorant"]}
             coachRank={["Radiant"]}
             onClick={() => window.open("https://google.com/")}
          /> 
          */}
        </div>
      </div>
      </div>
          //Wir suchen aktuell Coaches, wenn du Interessiert bist und Erfahrung im Coachen hast melde dich bei uns.
    );
  };
  
export default Coaching;