import './NotFound.css';

function NotFound() {
    return (
        <div className="page-container">
          <h1><br />Page not Found!</h1>
          <p>The page you're looking for may have been moved, deleted, or doesn't exist.</p>
          <p>Please go back to our landing Page.</p>
        </div>
    );
  };
  
export default NotFound;