import './Tournaments.css';

function Tournaments() {
    return (
        <div class="page-container">
          <h1 className='h-tournaments'><br /><br />Our Tournaments</h1>
          <p className='tournaments-text'>This is just the beginning, we are currently looking for a Riot license to organize tournaments with prize money.</p>
          <p className='tournaments-text'>There will be more here when the time is right!</p>
        </div>
    );
  };
  
export default Tournaments;