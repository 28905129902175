import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './AdminMainMenu.css';
import { validateSession, handleLogin, getCookie } from '../../../utils/authUtils';
import { useParams, Link } from "react-router-dom";
import 'react-quill/dist/quill.snow.css'; // Import Quill's styles

function AdminPanel() {
    const { t } = useTranslation();
    const { lang } = useParams();

    const [username, setUsername] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const checkSession = async () => {
            const valid = await validateSession();
            setIsLoggedIn(valid);
        };
        checkSession();
    }, []);

    const handleLoginFormSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const result = await handleLogin(username, authCode);
        setIsLoggedIn(result.success);
        setMessage(result.message);
        setIsLoading(false);
    };

    const buttonData = [
        { image: '/images/new_contract.png', text: 'Create Contract', link: '/prg-admin/create-contract' },
        { image: '/images/contract_ico.png', text: 'Manage Contracts [WIP]', link: '/prg-admin/' },
        { image: '/images/wip.png', text: 'Manage Teams [WIP]', link: '/prg-admin/' },
        { image: '/images/wip.png', text: 'Edit Merchandise [WIP]', link: '/prg-admin/' },
        { image: '/images/wip.png', text: 'PLACEHOLDER [WIP]', link: '/prg-admin/' },
        { image: '/images/wip.png', text: 'PLACEHOLDER [WIP]', link: '/prg-admin/' },
    ];

    return (
        <div className="admin-menu-page">
            <br></br>
            <br></br>
            <br></br>
            <h1 className="socials-title">Purge eSports Admin Panel</h1>

            {(isLoggedIn || getCookie("connect.sid")) ? (
                <div className="logged-in-container">
                    <p>Welcome to the Admin Panel <strong>PRG {getCookie("connect.user")}</strong></p>
                    <br/>
                    <p>Here you can Create or Manage Contracts [SOON],<br/> Make Changes to the displayed Teams/Players [SOON]<br/> and Edit the listed Merchandise [SOON]!</p>
                    
                    <div className="button-grid">
                        {buttonData.map((button, index) => (
                            <Link to={`/${lang}` + button.link} className="button-link">
                                <button key={index} className="grid-button">
                                    <img src={button.image} alt={`Button ${index + 1}`} className="button-image" />
                                    <span className="button-text">{button.text}</span>
                                </button>
                            </Link>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="login-container">
                    <h2>Login</h2>
                    <form onSubmit={handleLoginFormSubmit}>
                        <input
                            type="text"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            placeholder="Username"
                        />
                        <input
                            type="text"
                            value={authCode}
                            onChange={(e) => setAuthCode(e.target.value)}
                            placeholder="Authenticator Code"
                        />
                        <button type="submit" disabled={isLoading}>
                            {isLoading ? 'Logging in...' : 'Login'}
                        </button>
                    </form>
                    <p>{message}</p>
                </div>
            )}
        </div>
    );
}

export default AdminPanel;