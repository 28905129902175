import './Imprint.css';

function Imprint() {
    return (
        <div className="imprint-page">
            <h1 style={{ textAlign: 'center' }}><br />Imprint</h1>
            <div style={{ padding: '20px', lineHeight: '1.6', fontSize: '1rem' }}>
                <h3>Information according to § 5 TMG</h3>
                <p>
                    <strong>Jan Waschinski / Adrian Kopp</strong><br />
                    c/o COCENTER<br />
                    Koppoldstr. 1<br />
                    86551 Aichach<br />
                    Germany
                </p>
                <h2>Contact</h2>
                <p>
                    Email: <a href="mailto:business@prgpurge.com">business@prgpurge.com</a>
                </p>
                <h3>Responsible for content according to § 55 para. 2 RStV</h3>
                <p>
                    <strong>Jan Waschinski / Adrian Kopp</strong><br />
                    c/o COCENTER<br />
                    Koppoldstr. 1<br />
                    86551 Aichach<br />
                    Germany
                </p>
                <h2>Disclaimer</h2>
                <p>
                    The contents of our website were created with great care. However, we cannot guarantee the accuracy, completeness, or timeliness of the content.
                </p>
                <h2>Liability for links</h2>
                <p>
                    Our website contains links to external websites operated by third parties. We have no control over the content of these websites and therefore cannot assume any liability for these external contents. The respective provider or operator of the linked websites is always responsible for their content.
                </p>
                <h2>Copyright</h2>
                <p>
                    The content and works on this website created by the operators are subject to German copyright law. Contributions by third parties are marked as such. Any reproduction, editing, distribution, or use beyond the scope of copyright law requires the written consent of the respective author or creator.
                </p>
                <h2>Use of images</h2>
                <p>
                    Some images on this website may be subject to copyright and have been provided by our members. While we strive to ensure that all images are free to use, we cannot guarantee this for every image. If you believe your copyrighted material has been used without authorization, please contact us at <a href="mailto:business@prgpurge.com">business@prgpurge.com</a>, and we will promptly remove the material in question.
                </p>
            </div>
        </div>
    );
}

export default Imprint;
