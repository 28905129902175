import React from 'react';
import { useParams, Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import './Footer.css';

function Footer() {
  const { t } = useTranslation();
  const { lang } = useParams();

  return (
    <footer className="footer">
      <div className="footer-content">
        <p>
          Copyright &copy; 2024 Purge eSports. All rights reserved.
        </p>
        <Link to={`/${lang}/imprint`}>{t('pages.imprint')}</Link>
      </div>
    </footer>
  );
}

export default Footer;