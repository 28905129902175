import './Contact.css';
import { FaDiscord, FaEnvelope } from 'react-icons/fa';

function Contact() {
    return (
        <div className="contact-page">
          <h1 style={{textAlign: 'center'}}><br />Contact us</h1>
          <div className="contact-info">
            <div className="contact-item">
              <FaEnvelope className="contact-icon" />
              <div>
                <p>By using our Email:</p>
                <a href="mailto:business@prgpurge.com">
                  business@prgpurge.com
                </a>
              </div>
            </div>
            <div className="contact-item">
              <FaDiscord className="contact-icon" />
              <div>
                <p>By joining our Discord Server:</p>
                <a href="https://discord.gg/purgeesports" target="_blank" rel="noopener noreferrer">
                  https://discord.gg/purgeesports
                </a>
              </div>
            </div>
            <iframe src="https://discord.com/widget?id=767139170350792704&theme=dark" width="100%" height="450" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
          </div>
        </div>
    );
}

export default Contact;