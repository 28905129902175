import React, { useState, useCallback } from 'react';
import './TeamContainer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTiktok, faDiscord, faTwitter, faInstagram, faYoutube, faTwitch } from '@fortawesome/free-brands-svg-icons';

library.add(faTiktok, faDiscord, faTwitter, faInstagram, faYoutube, faTwitch);


const CoachSection = ({ team, gameSrc, extraInfo, logoSrc, teamgame, teamName, teamdesc, leaderNames, coachNames, playerNames, ersatzNames, tryoutNames, onCopy }) => {
  const [showPopup, setShowPopup] = useState(false);

  const handleCopy = () => {
    onCopy();
    setShowPopup(true);
    setTimeout(() => setShowPopup(false), 1000); 
  };

  const openURLinNewTab = useCallback(() => {
    console.log('Button clicked, extraInfo:', extraInfo); // Debug log
    if (extraInfo && extraInfo.socials && extraInfo.socials.tracker) {
      window.open(extraInfo.socials.tracker, '_blank', 'noopener,noreferrer');
    } else {
      console.warn('Tracker URL is not defined for this player');
      alert('Tracker URL is not available for this player.'); // User feedback
    }
  }, [extraInfo]);

  const iconClassMapping = {
    tiktok: ['fab', 'tiktok'],
    discord: ['fab', 'discord'],
    twitter: ['fab', 'twitter'],
    instagram: ['fab', 'instagram'],
    youtube: ['fab', 'youtube'],
    twitch: ['fab', 'twitch'],
  };

  const renderSection = (title, names, type) => {
    if (type === "header") {
      return <h3 class="team-h3">{title}</h3>;
    }
    if (type === "content") {
      return (
        <div className="player-item">
          {names.map((name, index) => <div id={name} key={index}>{name}</div>)}
        </div>
      );
    }
  };

  const renderTeams = () => {
    if (team === true) {
      return (
        <div className="player-section-teams">
          {renderSection("Leader", leaderNames, "header")}
          {renderSection("Coach", coachNames, "header")}
          {renderSection("Spieler", playerNames, "header")}
          {renderSection("Ersatz", ersatzNames, "header")}
          {renderSection("Tryout", tryoutNames, "header")}
          {renderSection("Leader", leaderNames, "content")}
          {renderSection("Coach", coachNames, "content")}
          {renderSection("Spieler", playerNames, "content")}
          {renderSection("Ersatz", ersatzNames, "content")}
          {renderSection("Tryout", tryoutNames, "content")}
        </div>
      );
    } else {
      return null;
    }
  }

  const renderSolo = () => {
    if (team === true) {
      return null;
    } else {
      if (extraInfo != null) {
        // TODO Render Social Buttons
        if (extraInfo.socials) {
          return (
            <div className="solo-content-socials">
              {extraInfo.socials.tracker && (
                <button className='player-tracker-button' onClick={openURLinNewTab}>
                  Tracker
                </button>
              )}
              {Object.entries(extraInfo.socials).map(([name, url], idx) => (
                url && (
                  <a key={idx} href={url} target="_blank" rel="noopener noreferrer" className="social-link">
                    <FontAwesomeIcon icon={iconClassMapping[name]} />
                  </a>
                )
              ))}
            </div>
          ); 
        } else {
          return null;
        }
      } else {
        return null;
      }
    }
  }

  const RenderDiscordButton = () => {
    if (team === true) {
      return (
        <button className='contact-leader-button' onClick={handleCopy}>
          {showPopup ? "Copied discord tag to clipboard" : "Leaders Discord"}
        </button>
      );
    } else {
      if (extraInfo != null) {
        if (extraInfo.proPlayer === true) {
          return (
            <div className="solo-content">
              <p>
                Pro Player
              </p>
            </div>
          );
        } else if (extraInfo.academyPlayer === true) {
          return (
            <div className="solo-content">
              <p>
                Academy Player
              </p>
            </div>
          );
        }
      } else {
        return null;
      }
    }
  }

  return (
    <div id={teamgame} className="grid-subcontainer-teams">
      <div className="grid-item-teams logo-section-teams">
        <img src={logoSrc} alt={`Logo ${teamName}`} className="jersey-image" />
      </div>
      <div className="grid-item-teams">
        <div className='header-section-teams'>
          <div className="header-placeholder">
            <img src={gameSrc} alt={teamgame} className="placeholder-image" />
            <h3>{teamName}</h3>
          </div>
          {RenderDiscordButton()}
        </div>
        <div className="info-section-teams">
          <p>{teamdesc}</p>
          {renderTeams()}
        </div>
        {renderSolo()}
      </div>
    </div>
  );
};

export default CoachSection;