import React, { useState, useEffect } from 'react';
import './ContractAdminForm.css';
import ReactQuill from 'react-quill';
import { Navigate } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css'; // Import Quill's styles
import { validateSession, handleLogin, getCookie } from '../../../utils/authUtils';
import { Route } from 'react-router-dom';

function ContractForm() {
    // State variables for form fields and message
    const [username, setUsername] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // New state for login status

    const [pName, setpName] = useState('');
    const [pTitle, setpTitle] = useState('');
    const [pAgreement, setpAgreement] = useState('');
    const [pCompensation, setpCompensation] = useState('');
    const [pStreaming, setpStreaming] = useState('');
    const [pRevenueSharing, setpRevenueSharing] = useState('');
    const [language, setLanguage] = useState('DE'); // Added state for language (DE/EN)
    const [contractText, setContractText] = useState(''); // State to store custom contract text

    const [currentDate, setCurrentDate] = useState("");

    const [contractType, setContractType] = useState('content_creator'); // Initial contract type (content_creator) Other Types: 'player'

    const [contractDetails, setContractDetails] = useState({
        playerName: pName,
        streamingHours: pStreaming,
        monthlyCompensation: pCompensation,
        playerAddress: 'Player Address', // Example static data for address
        playerCity: 'Player City', // Example static data for city
        currentDate: new Date().toLocaleDateString('de-DE'),
      });
    // CC stands for Content Creator

    const germanContractCC = {
        title: '<h1 style={{ textAlign: "center", fontSize: "18px", marginBottom: "20px" }}>Vertrag zwischen {contractDetails.playerName} und Auftraggeber</h1>',
        between: `Zwischen: <strong>{contractDetails.playerName}</strong>, {contractDetails.playerAddress}, {contractDetails.playerCity}<br />und <strong>Purge eSports</strong>, Dottenheimer Hauptstraße 5, 91463 Dietersheim`,
        titles: [
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>1. Vertragsgegenstand</h2>`,
                section: "contractSubject"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>2. Vertragsbeginn und Laufzeit</h2>`,
                section: "startDate"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>3. Rechte und Pflichten der Parteien</h2>`,
                section: "duties"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>4. Vergütung/Zahlung</h2>`,
                section: "compensation"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>5. Vertragsänderungen</h2>`,
                section: "contractChanges"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>6. Kündigung</h2>`,
                section: "termination"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>7. Haftung</h2>`,
                section: "liability"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>8. Datenschutz und Vertraulichkeit</h2>`,
                section: "privacy"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>9. Gerichtsstand und anwendbares Recht</h2>`,
                section: "jurisdiction"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>10. Schriftform und Salvatorische Klausel</h2>`,
                section: "formalities"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>11. Sonstige Bestimmungen</h2>`,
                section: "additionalPoints"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>12. Streitbeilegung</h2>`,
                section: "disputeResolution"
            }
        ],
        contractSubject: {
            text: [
                `<p>Erstellung von Videos für verschiedene Plattformen (z. B. YouTube, Twitch, TikTok).`,
                `<br/>Livestreaming von Esport-Events und Turnieren.`,
                `<br/>Erstellung von Blogbeiträgen und Artikeln über relevante Esport-Themen.`,
                `<br/>Bewerbung der Social Media Kanöle des Auftraggebers.`,
                `<br/>Teilnahme an Events und Promotion-Aktivitäten.</p>`
            ]
        },
        startDate: {
            text: [
                `<p>Dieser Vertrag tritt am {BITTE AUSFÜLLEN} in Kraft. Die Laufzeit des Vertrages beträgt sechs Monate. Der Vertrag endet somit automatisch am 15. Juni 2025. Eine Verlängerung des Vertrages ist nur schriftlich möglich. Die Verlängerung bedarf der ausdrücklichen Zustimmung beider Vertragsparteien. Es gilt eine Probezeit von zwei Monaten, beginnend mit dem 15. Dezember 2024. Während der Probezeit kann jede Vertragspartei den Vertrag ohne Einhaltung einer Frist und ohne Angabe von Gründen durch schriftliche Erklärung gegenüber der anderen Vertragspartei kündigen.</p>`
            ]
        },
        duties: {
            text: [
                `<p>Erstellung von Content mit dem PRG Branding: Die Parteien vereinbaren, dass der erstellte Content das PRG Branding trägt. Dies beinhaltet die Verwendung des PRG Logos, der Farbpalette und des allgemeinen Designs.`,
                `<br/>Streaming von mindestens 24 Stunden pro Monat: Die Parteien verpflichten sich, mindestens 24 Stunden an Streaming-Content pro Monat bereitzustellen. Dies kann in Form von Stream oder Videomaterial sein.`,
                `<br/>Aufbringung von mindestens 8 Stunden monatlicher Contenterstellung: Der Content Creator verpflichtet sich, mindestens 8 Stunden an neuem Content pro Monat zu erstellen. Dies kann Videos, Artikel, Podcasts oder andere Arten von Content umfassen.`,
                `<br/>Der Auftraggeber verpflichtet sich 40 Euro im monatlichen Intervall an das vom Content Creator angegebene Konto bis zum Ende der Vertragslaufzeit zu überweißen. Ebenfalls wird ein Jersey zur Verfügung gestellt um die Zugehörigkeit des Creators zum Team darzustellen.</p>`,
                `<br/>`,
                `<p><strong>Contenterstellung:</strong></p>`,
                `<p>Der Auftragnehmer erklärt, dass er der Urheber der erstellten Inhalte ist und dass diese frei von Rechten Dritter sind.`,
                `<br/>Der Auftraggeber erhält das Recht, die Inhalte in allen Medien zu verwenden, zu bearbeiten, zu verbreiten und zu verwerten, einschließlich der Nutzung in sozialen Medien, auf der Website und in Marketingmaterialien.`,
                `<br/>Der Auftraggeber erhält eine unbefristete, nicht-exklusive Lizenz für die Nutzung der Inhalte.`,
                `<br/>Der Auftragnehmer verpflichtet sich, alle vertraulichen Informationen des Auftraggebers vertraulich zu behandeln.`,
                `<br/>Der Auftragnehmer verpflichtet sich, die Inhalte in hoher Qualität und nach den Vorgaben des Auftraggebers zu erstellen.`,
                `<br/>Der Auftragnehmer stellt die Inhalte in den vom Auftraggeber gewünschten Formaten zur Verfügung.`,
                `<br/>Der Auftragnehmer stellt sicher, dass die Inhalte keine Rechte Dritter verletzen.`,
                `<br/>Der Auftraggeber hat das Recht, die Inhalte nachträglich zu ändern oder zu ergänzen.`,
                `<br/>Der Auftragnehmer hat das Recht, vom Vertrag zurückzutreten, wenn die Inhalte gegen die Gesetze oder die guten Sitten verstoßen.</p>`,
                `<br/>`,
                `<p><strong>Hochgeladener Content:</strong></p>`,
                `<p>Der Auftragnehmer ist für den hochgeladenen Content verantwortlich und haftet für alle daraus entstehenden Schäden.`,
                `<br/>Der hochgeladene Content darf keine illegalen, diffamierenden, obszönen oder beleidigenden Inhalte enthalten.`,
                `<br/>Der Auftragnehmer darf keine Markenrechte des Auftraggebers oder anderer Unternehmen verletzen.`,
                `<br/>Der Auftragnehmer darf keine persönlichen Daten von Personen ohne deren Einwilligung veröffentlichen.`,
                `<br/>Der Auftragnehmer verpflichtet sich, den Content verantwortungsvoll zu nutzen und keine Inhalte zu veröffentlichen, die den Ruf des Auftraggebers schädigen könnten.</p>`,
                `<br/>`,
                `<p><strong>Vorgegebener Content:</strong></p>`,
                `<p>Der Auftragnehmer verpflichtet sich, den Content innerhalb der vereinbarten Frist zu liefern.`,
                `<br/>Der Auftragnehmer hält den Auftraggeber über den Fortschritt der Content-Erstellung auf dem Laufenden.`,
                `<br/>Der Auftragnehmer stellt sicher, dass der Content den Vorgaben des Auftraggebers entspricht.`,
                `<br/>Der Auftraggeber hat das Recht, Feedback zum Content zu geben.`,
                `<br/>Der Auftragnehmer nimmt die vom Auftraggeber gewünschten Korrekturen am Content vor.&nbsp;</p>`,
                `<br/>`,
                `<p><strong>Werbung:</strong></p>`,
                `<p>Die Werbung muss auf die Zielgruppe des Contents abgestimmt sein.`,
                `<br/>Die Werbung darf keine Inhalte enthalten, die gegen die guten Sitten verstoßen.`,
                `<br/>Die Werbung muss klar als solche gekennzeichnet sein.`,
                `<br/>Die Werbung muss die gesetzlichen Vorgaben einhalten.`,
                `<br/>Der Auftraggeber hat das Recht, die Werbung zu kontrollieren und zu genehmigen.`,
                `<br/>Der Auftragnehmer verpflichtet sich, keine Werbung zu schalten, die den Ruf des Auftraggebers schädigen könnte.</p>`
            ]
        },
        compensation: {
            text: [
                `<p>Der Content Creator erhält eine monatliche Vergütung von 40 Euro, die auf dem vereinbarten Konto des Creators vorgenommen wird. Zahlungen erfolgen stets auf das vom Content Creator angegebene Konto und geschehen monatlich. Zusätzlich zum finanziellen Ausgleich erhält der Content Creator ein offizielles Jersey von Purge eSports, um seine Zugehörigkeit zum Team zu demonstrieren.</p>`
            ]
        },
        contractChanges: {
            text: [
                `<p>Änderungen oder Ergänzungen des Vertrages bedürfen der Schriftform und der Zustimmung beider Parteien. Dies gilt auch für die Aufhebung dieser Schriftformklausel. Jegliche Abänderungen oder Ergänzungen des Vertrages, die nicht in schriftlicher Form und mit der Unterschrift beider Parteien erfolgen, sind ungültig. Die Vertragsparteien verpflichten sich, sich gegenseitig unverzüglich schriftlich zu informieren, wenn Änderungen oder Ergänzungen des Vertrages beabsichtigt sind.</p>`
            ]
        },
        termination: {
            text: [
                `<p>Der Vertrag kann mit einer Frist von vier Wochen zum Monatsende gekündigt werden. Sollte der Vertrag vorzeitig aufgelöst werden wollen, muss eine außerordentliche Kündigung bei schwerwiegenden Vertragsverletzungen gegeben sein. Beispiele hierfür sind die Nichteinhaltung der vereinbarten Streaming Zeiten oder die Nicht-Aufnahme des vereinbarten Contents. Sollte es beispielsweise vorkommen, dass die vereinbarten Streaming Zeiten regelmäßig nicht eingehalten werden oder der Content nicht wie vereinbart aufgenommen wird, kann der Vertrag ohne Einhaltung der regulären Kündigungsfrist beendet werden.</p>`
            ]
        },
        liability: {
            text: [
                `<p>Beide Parteien haften nur für vorsätzlich oder grob fahrlässig verursachte Schäden. Das bedeutet, dass sie nur für Schäden verantwortlich sind, die sie mit Absicht oder durch grobe Nachlässigkeit verursacht haben. Eine Haftung für einfache Fahrlässigkeit ist ausgeschlossen. Eine Haftungsbegrenzung gilt im gesetzlich zulässigen Umfang. Dies bedeutet, dass die Haftung der Parteien auf den gesetzlich maximal zulässigen Betrag begrenzt ist. Dieser Betrag kann je nach Rechtsordnung und Sachverhalt variieren.`,
                `<br/>Der Auftragnehmer haftet für die Richtigkeit und Vollständigkeit der erstellten Inhalte.</p>`
            ]
        },
        privacy: {
            text: [
                `<p>Der Umgang mit personenbezogenen Daten erfolgt gemäß den gesetzlichen Vorschriften, was bedeutet, dass alle Maßnahmen und Verfahren, die zur Verarbeitung dieser Daten notwendig sind, in Übereinstimmung mit den geltenden Gesetzen und Richtlinien durchgeführt werden. Dies stellt sicher, dass die Privatsphäre und die Rechte der betroffenen Personen gewahrt bleiben und dass die Daten sicher und verantwortungsvoll behandelt werden.`,
                `<br/>Der Content Creator verpflichtet sich, vertrauliche Informationen nicht an Dritte weiterzugeben, was bedeutet, dass er alle notwendigen Vorkehrungen trifft, um sicherzustellen, dass sensible Daten, die ihm anvertraut werden, geschützt bleiben und nicht ohne ausdrückliche Zustimmung der betroffenen Personen oder ohne rechtliche Grundlage weitergegeben werden. Diese Verpflichtung ist entscheidend, um das Vertrauen der Nutzer zu gewinnen und die Integrität der Informationen zu wahren.</p>`
            ]
        },
        jurisdiction: {
            text: [
                `<p>Gerichtsstand ist das Amtsgericht Ansbach. Für alle Streitigkeiten aus oder im Zusammenhang mit diesem Vertrag gilt ausschließlich deutsches Recht.</p>`
            ]
        },
        formalities: {
            text: [
                `<p>Änderungen an diesem Vertrag bedürfen der Schriftform. Das bedeutet, dass jede Änderung des Vertrags schriftlich festgehalten werden muss, um gültig zu sein. Mündliche Absprachen oder Änderungen sind nicht gültig.</p>`,
                `<br/>`,
                `<p>Sollte eine Bestimmung dieses Vertrages unwirksam sein, bleiben die übrigen Bestimmungen in Kraft. Dies bedeutet, dass die Gültigkeit der restlichen Vertragsbestimmungen von der Unwirksamkeit einer einzelnen Bestimmung nicht berührt wird. Die unwirksame Bestimmung wird durch eine Regelung ersetzt, die dem wirtschaftlichen Zweck der unwirksamen Bestimmung am nächsten kommt.</p>`
            ]
        },
        additionalPoints: {
            text: [
                `<p>Zusätzliche Vereinbarungen, wie die Nutzung spezieller Plattformen oder Ressourcen, sind schriftlich zu dokumentieren. Diese Dokumentation dient als Nachweis für die vereinbarten Bedingungen und stellt sicher, dass alle Beteiligten über die Details der Nutzung informiert sind. Schiedsgerichtsklauseln werden nach beiderseitiger Zustimmung festgelegt. Dieser Schritt ermöglicht es den Parteien, im Falle von Streitigkeiten einen unabhängigen und neutralen Schlichter einzuschalten, um eine faire und effiziente Lösung zu finden.</p>`
            ]
        },
        disputeResolution: {
            text: [
                `<p>Im Falle von Streitigkeiten, die zwischen den Parteien auftreten, verpflichten sich diese zunächst, eine einvernehmliche Lösung durch Mediation anzustreben. Bevor rechtliche Schritte eingeleitet werden, soll ein gemeinsamer Konsens durch die Vermittlung eines neutralen Dritten angestrebt werden. Dies ermöglicht es den Parteien, ihre Differenzen in einem konstruktiven und kooperativen Rahmen zu lösen, ohne aufwendige und kostspielige Gerichtsverfahren in Kauf nehmen zu müssen.</p>`
            ]
        }
    };

    const englishContractCC = {
        title: '<h1 style={{ textAlign: "center", fontSize: "18px", marginBottom: "20px" }}>Contract between {contractDetails.playerName} and Client</h1>',
        between: `Between: <strong>{contractDetails.playerName}</strong>, {contractDetails.playerAddress}, {contractDetails.playerCity}<br />and <strong>Purge eSports</strong>, Dottenheimer Hauptstraße 5, 91463 Dietersheim`,
        titles: [
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>1. Subject of the Contract</h2>`,
                section: "contractSubject"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>2. Contract Start and Duration</h2>`,
                section: "startDate"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>3. Rights and Obligations of the Parties</h2>`,
                section: "duties"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>4. Compensation/Payment</h2>`,
                section: "compensation"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>5. Contract Amendments</h2>`,
                section: "contractChanges"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>6. Termination</h2>`,
                section: "termination"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>7. Liability</h2>`,
                section: "liability"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>8. Data Protection and Confidentiality</h2>`,
                section: "privacy"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>9. Jurisdiction and Applicable Law</h2>`,
                section: "jurisdiction"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>10. Written Form and Severability Clause</h2>`,
                section: "formalities"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>11. Additional Provisions</h2>`,
                section: "additionalPoints"
            },
            {
                title: `<h2 style={{ fontSize: "16px", marginTop: "20px" }}>12. Dispute Resolution</h2>`,
                section: "disputeResolution"
            }
        ],
        contractSubject: {
            text: [
                `<p>Creation of videos for various platforms (e.g., YouTube, Twitch, TikTok).</p>`,
                `<p>Livestreaming of esports events and tournaments.</p>`,
                `<p>Writing blog posts and articles on relevant esports topics.</p>`,
                `<p>Promoting the client's social media channels.</p>`,
                `<p>Participation in events and promotional activities.</p>`
            ]
        },
        startDate: {
            text: [
                `<p>This contract comes into effect on {DATE HERE}. The contract duration is six months and will automatically end on June 15, 2025. An extension of the contract is only possible in writing with the express consent of both parties. A probation period of two months, starting December 15, 2024, is included. During the probation period, either party may terminate the contract without notice or reason by providing written notice to the other party.</p>`
            ]
        },
        duties: {
            text: [
                `<p>Creation of content featuring PRG branding: The parties agree that all created content will include PRG branding, which includes the use of the PRG logo, color palette, and general design.`,
                `<br/>Streaming at least 24 hours per month: The parties commit to providing at least 24 hours of streaming content per month in the form of live streams or video material.`,
                `<br/>Creating at least 8 hours of content monthly: The content creator agrees to produce at least 8 hours of new content monthly, which can include videos, articles, podcasts, or other forms of content.`,
                `<br/>The client agrees to pay €40 in monthly intervals to the account specified by the content creator until the end of the contract term. Additionally, a jersey will be provided to demonstrate the creator's association with the team.</p>`,
                `<p><strong>Content Creation:</strong></p>`,
                `<p>The contractor declares that they are the creator of the content and that it is free from third-party rights.`,
                `<br/>The client receives the right to use, edit, distribute, and exploit the content in all media, including social media, the website, and marketing materials.`,
                `<br/>The client receives a perpetual, non-exclusive license to use the content.`,
                `<br/>The contractor agrees to keep the client's confidential information confidential.`,
                `<br/>The contractor agrees to create high-quality content according to the client's specifications.`,
                `<br/>The contractor provides the content in the formats required by the client.`,
                `<br/>The contractor ensures that the content does not infringe on third-party rights.`,
                `<br/>The client has the right to modify or supplement the content afterwards.`,
                `<br/>The contractor has the right to withdraw from the contract if the content violates laws or public decency.</p>`
            ]
        },
        compensation: {
            text: [
                `<p>The content creator will receive a monthly compensation of €40, which will be transferred to the account provided by the creator. Payments will be made monthly. Additionally, the content creator will receive an official Purge eSports jersey to demonstrate their association with the team.</p>`
            ]
        },
        contractChanges: {
            text: [
                `<p>Changes or amendments to the contract require written form and the consent of both parties. This also applies to the cancellation of the written form requirement. Any changes or amendments to the contract that are not made in writing and signed by both parties are invalid. The parties agree to inform each other in writing without delay if changes or amendments to the contract are intended.</p>`
            ]
        },
        termination: {
            text: [
                `<p>The contract can be terminated with a notice period of four weeks to the end of the month. Early termination is only possible in the case of significant breaches of the contract, such as failure to meet agreed streaming times or content creation. If the agreed streaming times are regularly missed, or the content is not created as agreed, the contract can be terminated without notice.</p>`
            ]
        },
        liability: {
            text: [
                `<p>Both parties are only liable for damages caused intentionally or through gross negligence. This means they are only responsible for damages caused deliberately or through gross carelessness. Liability for simple negligence is excluded. Liability is limited to the extent permitted by law.</p>`,
                `<p>The contractor is liable for the accuracy and completeness of the created content.</p>`
            ]
        },
        privacy: {
            text: [
                `<p>Handling of personal data is carried out in accordance with legal regulations, ensuring that all measures and processes for processing this data comply with applicable laws and guidelines.</p>`,
                `<p>The content creator agrees not to disclose confidential information to third parties and to take all necessary precautions to protect sensitive data entrusted to them.</p>`
            ]
        },
        jurisdiction: {
            text: [
                `<p>The jurisdiction is the local court of Ansbach. German law exclusively applies to all disputes arising from or in connection with this contract.</p>`
            ]
        },
        formalities: {
            text: [
                `<p>Changes to this contract must be made in writing to be valid. Oral agreements or changes are invalid.</p>`,
                `<p>If any provision of this contract is invalid, the remaining provisions remain in force. The invalid provision will be replaced by a regulation that comes closest to the economic purpose of the invalid provision.</p>`
            ]
        },
        additionalPoints: {
            text: [
                `<p>Additional agreements, such as the use of specific platforms or resources, must be documented in writing. Arbitration clauses are set by mutual agreement, allowing the parties to involve an independent and neutral arbitrator for disputes.</p>`
            ]
        },
        disputeResolution: {
            text: [
                `<p>In the event of disputes, the parties commit to seeking an amicable solution through mediation before taking legal action. Mediation by a neutral third party will help resolve differences constructively and cooperatively.</p>`
            ]
        }
    };
    

    const germanContractPL = {

    };

    const englishContractPL = {

    }


    useEffect(() => {
        const today = new Date();
        const formattedDate = `${today.getDate().toString().padStart(2, "0")}.${(today.getMonth() + 1)
            .toString()
            .padStart(2, "0")}.${today.getFullYear()}`;
        setCurrentDate(formattedDate);

        // Validate session on component mount
        const checkSession = async () => {
            const isValidSession = await validateSession();
            setIsLoggedIn(isValidSession);
        };

        checkSession();
    }, []);

    const handleLoginSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const loginSuccess = await handleLogin(username, authCode);
        setIsLoading(false);
        setIsLoggedIn(loginSuccess);
        setMessage(loginSuccess ? 'Login successful!' : 'Login failed. Please try again.');
    };

    // Toggle language
    const toggleLanguage = (lang) => {
        setLanguage(lang);
    };

    const uploadContract = async (e) => {
        const sessionId = getCookie('connect.sid');
        if (!sessionId) return;
        const contractHTML = document.getElementsByClassName('ql-editor')[0].innerHTML;
        // Generate HTML File
        const file = new File([contractHTML], 'contract.html', { type: 'text/html' });
        // Upload the File inside of a Post Request
        const formData = new FormData();
        formData.append('contract', file);
        const response = await fetch(`https://api.prgpurge.com/prg/api/upload-contract?cookie=${sessionId}&partner=${contractDetails.playerName}`, {
            method: 'POST',
            body: formData,
        });

        const body = await response.json();
        
        //console.log('Contract uploaded:', response);
        if (response.ok) {
            setMessage('Contract uploaded successfully! The Code was copied to your Clipboard. \nContract Code: '+body.contractCode);
            navigator.clipboard.writeText(body.contractCode).catch(err => {
                console.error('Failed to copy contract code to clipboard: ', err);
            });
        }
    }

    const downloadContract = () => {
        const contractHTML = document.getElementsByClassName('ql-editor')[0].innerHTML;
        // Create a Blob from the contract HTML
        const blob = new Blob([contractHTML], { type: 'text/html' });
        // Create a link element
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'contract.html'; // File name for the download
        // Trigger the download
        link.click();
        // Clean up the URL object
        URL.revokeObjectURL(link.href);
    };

    var currentContract;


    switch (contractType) {
        case 'content_creator':
            currentContract = language === 'DE' ? germanContractCC : englishContractCC;
            break;
        case 'player':
            currentContract = language === 'DE' ? germanContractPL : englishContractPL;
            break;
        default:
            currentContract = language === 'DE' ? germanContractCC : englishContractCC;
            console.error(`Invalid contract type: ${contractType}`);
            break;
    }


    // QuillJS handler
    const handleQuillChange = (value) => {
        setContractText(value); // Update contract text
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
      
        // Update contract details state
        setContractDetails({ ...contractDetails, [name]: value });
      };

    const initializeContractText = () => {
        var contractContent;
        switch (contractType) {
            case 'content_creator':
                contractContent = language === 'DE' ? germanContractCC : englishContractCC;
                break;
            case 'player':
                contractContent = language === 'DE' ? germanContractPL : englishContractPL;
                break;
            default:
                contractContent = language === 'DE' ? germanContractCC : englishContractCC;
                console.error(`Invalid contract type: ${contractType}`);
                break;
        }
        let contractText = `${contractContent.title}<br/>`;
        contractText += `${contractContent.between}<br/><br/>`;
    
        // Loop through each title and section to build the contract text
        contractContent.titles.forEach(titleObj => {
            contractText += `<br/>${titleObj.title}<br/>`;
    
            // Get the corresponding content for the section
            const sectionContent = contractContent[titleObj.section];
    
            // Ensure there's content in the section and append it
            if (sectionContent && sectionContent.text) {
                sectionContent.text.forEach(paragraph => {
                    contractText += `${paragraph}`;
                });
                contractText += `<br/>`;
            }
        });
    
        setContractText(contractText); // Set the contract text
    };
    

    useEffect(() => {
        initializeContractText(); // Initialize the contract text when the page loads
    }, [language]); // Re-run when the language changes


    return (
        <div className="contract-form-page-admin">
            <br></br>
            <br></br>
            <br></br>
            <h1 className="socials-title">Contract Form Admin Panel</h1>

            {(isLoggedIn || getCookie("connect.sid")) ? (
                <div className="logged-in-container">
                    <p>Welcome PRG {getCookie("connect.user")}</p>
                    <div className="language-switcher">
                        <button onClick={() => toggleLanguage('DE')}>DE</button>
                        <button onClick={() => toggleLanguage('EN')}>EN</button>
                    </div>
                    <div className="contract-section">
                        <h3>Edit Contract Text</h3>
                        <ReactQuill
                            value={contractText}
                            onChange={handleQuillChange}
                            theme="snow"
                        />
                    </div>
                    <br/>
                    <p>The Signatures will always be displayed at the Contracts End</p>
                    <label>
                        Who is this Contract for?
                        <input
                            type="text"
                            name="playerName"
                            value={contractDetails.playerName}
                            onChange={handleInputChange}
                        />
                    </label>
                    <p>{message}</p>
                    <button onClick={() => uploadContract()}>Publish Contract</button>
                </div>
            ) : (
                <Navigate to="/en/prg-admin" />
            )}
        </div>
    );
}

export default ContractForm;
